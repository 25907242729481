@mixin bg($url, $w, $h) {
    width: $w/2+px,;
    height: $h/2+px,;
    background: url($url) no-repeat 0 0;
    background-size: $w/2+px, $h/2+px;
    display: inline-block;
}
  
@mixin btn($fontColor, $color) {
    color: $fontColor!important;
    border-color: $color!important;
    background: $color!important;

    &:focus,&:hover {
    color:lighten($fontColor, $amount: 10)!important;
    border-color:lighten($color, $amount: 10)!important;
    background:lighten($color, $amount: 10)!important;
    }

    &:active {
    color:lighten($fontColor, $amount: 7)!important;
    border-color:lighten($color, $amount: 7)!important;
    background:lighten($color, $amount: 7)!important;
    }

    &[disabled] {
        border-color:#ccc!important;
        background: #ccc!important;
    }

    &.ant-btn-clicked:after {
        border-color: $color!important;
    }
}
  
@mixin btn-o($fontColor, $color) {
    color: $fontColor!important;
    border-color: $fontColor!important;
    border-width: 2px!important;
    background: $color!important;

    &:focus,&:hover {
    color:lighten($fontColor, $amount: 10)!important;
    border-color:lighten($fontColor, $amount: 10)!important;
    background:lighten($color, $amount: 10)!important;
    }

    &:active {
    color:lighten($fontColor, $amount: 7)!important;
    border-color:lighten($fontColor, $amount: 7)!important;
    background:lighten($color, $amount: 7)!important;
    }

    &[disabled] {
        border-color:#ccc!important;
        background: #ccc!important;
    }

    &.ant-btn-clicked:after {
        border-color: $fontColor!important;
    }
}
  