@import './assets/utils.scss';

:global {

.rich-text {
  position: relative;
  margin: 0;
  overflow: auto;
  font-family: 'lucida Grande', Verdana, Helvetica, 'Microsoft Yahei', arial,
    sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  word-wrap: break-word;
  white-space: normal;
  p {
    margin: 1em 0;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
    outline: none;
  }
  h1 {
    margin: 0.67em 0;
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    margin: 0.83em 0;
    font-size: 1.5em;
    font-weight: bold;
  }
  h3 {
    margin: 1.17em 0;
    font-size: 1.17em;
    font-weight: bold;
  }
  h4 {
    margin: 1.33em 0;
    font-weight: bold;
  }
  h5 {
    margin: 2.33em 0;
    font-size: 0.67em;
    font-weight: bold;
  }
  ul,
  ol {
    padding: 15px;
    margin: 0;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ol[type='a'] {
    list-style-type: lower-alpha;
  }
  ol[type='i'] {
    list-style-type: lower-roman;
  }
  ul li {
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol li {
    list-style-position: inside;
    list-style-type: inherit;
  }
  td {
    line-height: normal;
  }
  pre {
    width: 95%;
    word-wrap: break-word;
    white-space: pre-wrap;
    height: unset !important;
  }
  img {
    max-width: unset;
    vertical-align: middle;
    border: 0;
  }
  address,
  cite,
  dfn,
  em,
  var {
    font-style: italic;
  }
  blockquote {
    padding-left: 8px;
    margin: 16px 4px;
    border-left: 1px solid #ccc;
  }
  table {
    word-break: initial;
    border-collapse: separate;
  }
  .MsoNormal {
    margin: 0;
  }
  sup {
    vertical-align: super;
  }
  sub {
    vertical-align: sub;
  }
  sup,
  sub {
    font-size: smaller;
    position: static;
    top: 0;
    line-height: unset;
  }
  hr {
    border-style: inset;
    border-width: 1px;
  }
}

  html,body {
    min-height: 100%;
    font-family: -apple-system,Helvetica Neue,Arial,PingFang SC,Hiragino Sans GB,STHeiti,Microsoft YaHei,Microsoft JhengHei,Source Han Sans SC,Noto Sans CJK SC,Source Han Sans CN,Noto Sans SC,Source Han Sans TC,Noto Sans CJK TC,WenQuanYi Micro Hei,SimSun,sans-serif;
  }

  body {
    box-sizing: border-box;
    color: #333333;
    background: #f4f4f4;
  }

  *,*:before,*:after {
    box-sizing:inherit;
  }

  #app {
    height: 100%;
  }

  .wrap {
    width: 1280px;
    margin: 0 auto;
  }

  .wrap-small {
    width:1024px;
    margin: 0 auto;
  }

  a {
    &:focus {
      text-decoration: none;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .hide {
    display: none !important;
  }

  ul,li {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .text-muted {
    color: rgba(#000000, 0.45)
  }

  .text-orange {
    color: #f26e18;
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }

  .btn-type-1 {
    @include btn(#fff,#9ad4fe);
  }

  .btn-type-2 {
    @include btn(#333333,#d7e4f3);
  }

  .btn-type-3 {
    @include btn(#fff,#f26e18);
  }

  .btn-type-3-o {
    @include btn-o(#f26e18,#fff2e8);
  }

  .btn-type-4 {
    @include btn(black, #9ad4fe);
  }
  .btn-type-5 {
    @include btn(#fff,#0c79df);
  }



.table {
  $color:#f26e18;

  .ant-table-thead > tr, .ant-table-tbody > tr {
      transition: none;
  }

  .ant-table-thead th, .ant-table-tbody td{
    font-size: 12px;
    font-weight: normal;
    transition: none;
  }

  .ant-table-thead th {
      height: 30px;
      line-height: 30px;
      padding: 0 16px;
      background: $color;
      color: #fff;
      border-radius: 0!important;
  }

  .ant-table-tbody tr {
      &:hover {
          td:last-child {
              span {
                visibility: visible;
              }
          }
      }

      td.actions {
          > span {
              visibility: hidden;
          }
      }
  }
  .ant-table-tbody tr:last-child td {
      border-bottom: 1px solid $color;
  }


  &.blue {
    $color: #357cd7;


    .ant-table-thead th {
        background: $color;
    }

    .ant-table-tbody tr:last-child td {
        border-bottom: 1px solid $color;
    }
  }

  &.line-style {
    width: 883px;

    .ant-table-tbody > tr > td {
      padding-top: 50px;
      padding-bottom: 28px;
      border: 1px solid #d7e4f3;
      position: relative;
    }

    .ant-table-thead th {
      text-align: center;
    }

    .ant-table-tbody > tr:first-child > td {
      padding-top: 60px;
    }

    .ant-table-tbody > tr:first-child > td:nth-child(2)  {
      .trHeader {
        top: 8px;
  
        &:before {
          border-top: none;
        }
      }
    }

    .ant-table-tbody > tr:first-child> td.ant-table-selection-column {
      >span {
        top:14px;
      }
    }

    .ant-table-thead > tr > th.ant-table-selection-column{
      + th {
        > div {
          margin-left:-35px;
        }
      }

      > div {
        margin-left: -12px;
      }
    }

    .ant-table-tbody > tr > td.ant-table-selection-column {
      border-right: none;
      padding: 0;
      >span {    
        position: absolute;
        top: 6px;
        left: 15px;
        z-index: 12;
        transform: scale(0.9);
      }
    }

    .ant-table-tbody > tr> td:nth-child(2) {
      border-left: none;

      .colspanText {
        margin-left: -37px;
      }
    }

    .trHeader {
      width: 883px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #d7e4f3;
      border-right: none;
      background: #fafaf9;
      position: absolute;
      left: -62px;
      top: 0;
      z-index: 10;

      > span:first-child {
        margin-left: 45px;
      }
      
      .text-muted {
        font-size: 12px;
        color: rgba(#010000, .45);
        margin-right: 15px;

        >span {
          color: #333333;
        }
      }

      .right {
        float: right;
      }

      &:before {
        content: '';
        width: 884px;
        height: 12px;
        border-top: 1px solid #d7e4f3;
        border-bottom: 1px solid #d7e4f3;
        background:#fff;
        position: absolute;
        top: -12px;
        left: -1px;
      }
    }
  }
}

.icon {
  &.klarna {
    @include bg('./assets/images/pay-icon-1.png', 248, 86);
  }
  &.alipay  {
    @include bg('./assets/images/pay-icon-2.png', 278, 96);
  }
  &.wechat  {
    @include bg('./assets/images/pay-icon-wechat.png', 278, 96);
  }
  &.balance  {
    @include bg('./assets/images/pay-icon-3.png', 330, 98);
  }
  &.barclay  {
    @include bg('./assets/images/pay-visa-icon.png', 364, 128);
  }
  &.sofort  {
    @include bg('./assets/images/pay-sofort-icon.png', 300, 265);
  }
}

}
